<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useId, searchBarExculseTargetIds } from '@/utils/useId'
import { MOBILEIDENAVIGATIONIDS } from '@/utils/ide'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import projectsService from '@/services/ide/projects.service'

/**
 * Contains unique id
 */
const id = useId('searchBar', true)

const props = defineProps({
  isWithAnimation: {
    type: Boolean,
    required: false,
    default: false
  },
  isFullSize: {
    type: Boolean,
    required: false,
    default: true
  },
  isMini: {
    type: Boolean,
    required: false,
    default: false
  },
  isIdeMobileNav: {
    type: Boolean,
    required: false,
    default: false
  }
})

const router = useRouter()

const ideStore = useIdeStore()
const isDark = false
const jdroidStore = useJdroidStore()

const searchTerm = ref('')
const languages: ILanguage[] = [...languagesItems]
  .filter((langauge: ILanguage) => {
    return !langauge.isAdvanced
  })
  .sort((a: ILanguage, b: ILanguage) => (a.displayName > b.displayName ? 1 : -1))

const showDropdown = ref(false)

const isIdeView = computed(() => {
  return ideStore.isIdeView
})

const showingMobileInputTab = computed(() => {
  return ideStore.currentMobileTab == IDEVIEWTABS_MOBILE.INPUT
})

const links = computed(() => {
  if (searchTerm.value && searchTerm.value.trim() !== '') {
    let links = languages.filter((o: ILanguage) => {
      return o.searchTerm.includes(searchTerm.value.toLowerCase())
    })
    return links
  } else {
    return projectsService.groupFrameWorkLanguage(languages)
  }
})

const placeholder = computed(() => {
  return props.isMini ? 'Search Language' : 'Search IDEs Compiler or Terminal'
})

const isDefaultLightMode = computed(() => {
  if (ideStore.isIdeView) {
    if (isDark || ideStore?.colorMode == 'dark') return true
    return false
  }
  return false
})

/**
 * @param event Click event
 * If clicking outside of dropdown div, dropdown will disappear
 */
const clickEventHandler = (event: any) => {
  const inx = searchBarExculseTargetIds.findIndex((id) => id === event.target.id)
  if (inx === -1) {
    if (showDropdown.value) {
      if (event.target.id != 'codeSearchDropdown') {
        showDropdown.value = false
      } else {
        showDropdown.value = true
      }
    }
  }
}

interface slideItem {
  icon: string
  displayName: string
}

const autoSlideIndex = ref<number>(0)
const autoSlideItem = ref<slideItem>({
  icon: languages[autoSlideIndex.value].icon,
  displayName: languages[autoSlideIndex.value].displayName
})
const fadeEffect = ref<string>('')
const animationVisibility = ref<boolean>(props.isWithAnimation)
const searchBar: any = ref(null)

/**
 *
 */
const autoSlide = (): void => {
  animationVisibility.value = true
  fadeEffect.value = 'fadeIn'
  autoSlideIndex.value === languages.length - 1
    ? (autoSlideIndex.value = 0)
    : autoSlideIndex.value++
  autoSlideItem.value.icon = languages[autoSlideIndex.value].icon
  autoSlideItem.value.displayName = languages[autoSlideIndex.value].displayName

  setTimeout(() => {
    fadeEffect.value = 'fadeOut'
  }, 1000)
}

/**
 * Remove auto slide animation and show dropdown
 */
const removeAutoSlide = async () => {
  searchBar.value.focus()
  clearInterval(autoSlideInterval)
  animationVisibility.value = false
  await new Promise((resolve) => setTimeout(resolve, 100))
  showDropdown.value = true
}

let autoSlideInterval: any
/**
 * @param item Selected language
 * Start coding with selected language
 */
const startCoding = async (item: ILanguage) => {
  if (!item.categoryText) {
    if (ideStore.isIdeView) {
      jdroidStore.setStartCodingLanguage(item)
    } else {
      jdroidStore.setStartCodingLanguage(item)
      router.push('/start-coding')
    }
  }
}

onMounted(() => {
  document.addEventListener('click', clickEventHandler)
  autoSlideInterval = props.isWithAnimation && setInterval(autoSlide, 2000)
})

onUnmounted(() => {
  document.removeEventListener('click', clickEventHandler)
  removeAutoSlide
})
</script>

<template>
  <div
    class="relative h-10 print:hidden"
    :class="[
      { 'w-fit': props.isMini },
      { 'mb-4': !props.isMini },
      { 'w-full': showingMobileInputTab }
    ]"
  >
    <div class="relative h-full">
      <input
        autocomplete="off"
        ref="searchBar"
        :placeholder="!animationVisibility ? placeholder : ''"
        name="languageSearch"
        :id="`${id}`"
        class="linear background-primary block w-full rounded-lg border border-gray-900 outline-none transition-all delay-150 dark:border-gray-200"
        :class="[
          {
            'px-6 py-4 sm:py-5': props.isFullSize //Code page
          },
          {
            'h-full px-6 py-4': !props.isFullSize && !props.isMini
          },
          {
            'max-w-80 border-opacity-15 py-2 pe-6 ps-2.5 dark:border-opacity-30': props.isMini
          },
          {
            'section-tertiary': props.isMini && !showingMobileInputTab //Only if mini AND not mobile IDE view
          },
          {
            'section-quaternary ': props.isWithAnimation
          },
          {
            'bg-transparent text-[14px]': isIdeView //IDE header
          },
          {
            'dark:section-primary bg-white ': props.isIdeMobileNav //IDE header
          },
          {
            'section-secondary py-2': showingMobileInputTab //Mobile IDE
          }
        ]"
        v-model="searchTerm"
        @click="showDropdown = !showDropdown"
      />
      <div
        v-if="animationVisibility"
        @click="removeAutoSlide"
        :class="{ fadeOut: fadeEffect === 'fadeOut', fadeIn: fadeEffect === 'fadeIn' }"
        class="absolute left-0 top-0 flex h-full w-full items-center px-2 py-1.5"
        :id="MOBILEIDENAVIGATIONIDS.CODESEARCHBARID"
      >
        <img :src="autoSlideItem.icon" alt="language logo" class="mr-2 h-8 w-8 p-1" />
        <span :id="MOBILEIDENAVIGATIONIDS.CODESEARCHBARID" class="mr-8 truncate text-sm">{{
          autoSlideItem.displayName
        }}</span>
      </div>
    </div>

    <!-- Dropdown -->
    <div
      v-if="showDropdown"
      id="codeSearchDropdown"
      class="absolute right-0 z-40 mt-[1px] w-full min-w-36 rounded-lg p-3 lg:left-0"
      :class="[
        {
          'w-full': links.length > 10
        },
        {
          'sm:w-full md:max-w-md': links.length < 10
        },
        {
          'w-auto': props.isMini
        },
        {
          'background-primary stroke-primary-default border ': props.isWithAnimation
        },
        {
          'section-quaternary ': !showingMobileInputTab // When not mobile ide view
        },
        {
          'section-secondary': showingMobileInputTab // Mobile IDE language bar
        }
      ]"
    >
      <div
        class="max-h-60 overflow-y-auto md:grid"
        :class="
          props.isFullSize && links.length > 10
            ? 'gap-3 md:grid-cols-2 lg:grid-cols-3'
            : 'md:grid-cols-1'
        "
      >
        <button
          v-for="(item, indx) in links"
          :key="indx"
          class="w-full"
          @click="() => startCoding(item as ILanguage)"
          id="codeSearchDropdown"
        >
          <div
            class="text-low pointer-events-none w-full truncate pt-2 text-left text-sm font-medium"
          >
            {{ item.categoryText }}
          </div>
          <div
            v-if="!props.isMini && !item.categoryText"
            class="hover:section-secondary flex items-center justify-between rounded-md px-2 py-3"
          >
            <div>
              <!-- Dark -->
              <img
                :src="(item as ILanguage).darkIcon ? (item as ILanguage).darkIcon : (item as ILanguage).icon"
                :alt="(item as ILanguage).displayName"
                class="light:hidden me-2 w-8 dark:inline-block"
              />
              <!-- Light -->
              <img
                v-if="(item as ILanguage).darkIcon"
                :src="(item as ILanguage).icon"
                :alt="(item as ILanguage).displayName"
                class="light:inline-block me-2 w-8 dark:hidden"
              />
              <span class="inline-block text-sm text-text-primary dark:text-text-primary-dark">{{
                (item as ILanguage).displayName
              }}</span>
            </div>
            <span class="inline-block text-[10px]"
              >Code Now
              <FontAwesomeIcon
                icon="fa-arrow-right"
                class="w-3 text-text-primary dark:text-text-primary-dark"
              />
            </span>
            <!-- Empty message -->
          </div>
          <div
            v-if="props.isMini && !item.categoryText"
            class="hover:surface-secondary-base hover:text-high text-low flex items-center justify-between overflow-x-clip rounded-md px-2 py-3"
          >
            <div class="flex items-center justify-center gap-5">
              <!-- Dark -->
              <img
                v-if="isDefaultLightMode"
                :src="(item as ILanguage).darkIcon ? (item as ILanguage).darkIcon : (item as ILanguage).icon"
                :alt="(item as ILanguage).displayName"
                class="light:hidden h-5 w-5 dark:inline-block"
              />
              <!-- Light -->
              <img
                v-if="!isDark"
                :src="(item as ILanguage).icon"
                :alt="(item as ILanguage).displayName"
                class="light:inline-block h-5 w-5 dark:hidden"
              />
              <span class="inline-block truncate text-sm">{{
                (item as ILanguage).displayName
              }}</span>
            </div>
            <!-- Empty message -->
          </div>
        </button>
        <p v-if="links.length == 0" class="text-secondary text-sm">No language found.</p>
      </div>
    </div>
    <div
      v-if="!searchTerm"
      :class="isMini ? 'right-2.5' : 'right-5'"
      class="pointer-events-none absolute inset-y-0 flex items-center pl-3"
    >
      <FontAwesomeIcon
        icon="fa-magnifying-glass"
        class="h-4 w-4"
        :class="isIdeView ? 'sm:hidden xl:block' : ''"
      />
    </div>
    <button
      v-if="searchTerm"
      @click=";(searchTerm = ''), (showDropdown = false)"
      class="absolute inset-y-0 right-0 top-0 flex h-full items-center pr-3"
    >
      <FontAwesomeIcon icon="fa-circle-xmark" class="h-5 w-5" />
    </button>
  </div>
  <!-- Featured language icons -->
  <div class="flex w-full flex-col justify-between gap-3 sm:flex-row" v-if="!props.isMini">
    <a v-if="props.isFullSize" class="text-xs underline" href="#suggestALangauge"
      >Suggest a Language</a
    >
  </div>
</template>

<style scoped>
/* For webkit-based browsers (Chrome, Safari) */
#codeSearchDropdown ::-webkit-scrollbar {
  width: 8px;
  /* Adjust width as needed */
}

#codeSearchDropdown ::-webkit-scrollbar-thumb {
  background-color: #747474;
  /* Set the background color of the thumb */
}

/* For Firefox and some newer browsers */
/* Note: This property might not be supported in all browsers */
/* .scrollbar-color: #555 #fff; */
/* Set thumb color and track color */
.fadeOut {
  -webkit-animation: fadeOut 2s ease-in alternate;
  -moz-animation: fadeOut 2s ease-in alternate;
  animation: fadeOut 2s ease-in alternate;
}

.fadeIn {
  -webkit-animation: fadeIn 2s ease-in alternate;
  -moz-animation: fadeIn 2s ease-in alternate;
  animation: fadeIn 2s ease-in alternate;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
</style>
